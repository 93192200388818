import React from "react"

import LangProvider from "./src/context/langContext"

// export const onInitialClientRender = () => {
//     setTimeout(function() {
//         document.getElementById("___loader").style.display = "none"
//     }, 1000)
// }

export const wrapRootElement = ({ element }) => (
  <LangProvider>{element}</LangProvider>
)
