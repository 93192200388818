exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aboutus-js": () => import("./../../../src/pages/aboutus.js" /* webpackChunkName: "component---src-pages-aboutus-js" */),
  "component---src-pages-antypirat-js": () => import("./../../../src/pages/antypirat.js" /* webpackChunkName: "component---src-pages-antypirat-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-insight-js": () => import("./../../../src/pages/insight.js" /* webpackChunkName: "component---src-pages-insight-js" */),
  "component---src-pages-instruments-instruments-for-cinemas-js": () => import("./../../../src/pages/instruments/InstrumentsForCinemas.js" /* webpackChunkName: "component---src-pages-instruments-instruments-for-cinemas-js" */),
  "component---src-pages-instruments-instruments-for-distributors-js": () => import("./../../../src/pages/instruments/InstrumentsForDistributors.js" /* webpackChunkName: "component---src-pages-instruments-instruments-for-distributors-js" */),
  "component---src-pages-instruments-instruments-for-media-js": () => import("./../../../src/pages/instruments/InstrumentsForMedia.js" /* webpackChunkName: "component---src-pages-instruments-instruments-for-media-js" */),
  "component---src-pages-instruments-instruments-for-mediahouses-js": () => import("./../../../src/pages/instruments/InstrumentsForMediahouses.js" /* webpackChunkName: "component---src-pages-instruments-instruments-for-mediahouses-js" */),
  "component---src-pages-instruments-instruments-for-producers-js": () => import("./../../../src/pages/instruments/InstrumentsForProducers.js" /* webpackChunkName: "component---src-pages-instruments-instruments-for-producers-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-regulations-js": () => import("./../../../src/pages/regulations.js" /* webpackChunkName: "component---src-pages-regulations-js" */),
  "component---src-pages-reports-js": () => import("./../../../src/pages/reports.js" /* webpackChunkName: "component---src-pages-reports-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

